<template>
  <section id="">
    <ag-grid-table ref="grid-historico-optimizations" :debug="this.$route.query.debug == '1'"
      :configUrl="historicoOptimizationsConfig" :dataUrl="historicoOptimizationsData" @gridReady="onGridReady"
      @getRowsLoaded="onGetRowsLoaded" rowSelection="multiple" :rowClassRules="rowClassRules"
      :pinnedTopRowData="pinnedTopRowData" @selectionChanged="onSelectionChanged" :actions="actions"
      :getRowNodeId="getRowNodeId" :enableCellChangeFlash="true">
      <template v-slot:actions>
        <div>
          <b-button-toolbar aria-label="Toolbar with button groups and input groups" justify>
            <b-button-group style="margin-bottom: 1rem">
              <b-button size="sm" :disabled="disableCompareRuns" @click="compareOptimizations()" variant="primary">{{
                $t('Comparar') }}</b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
      </template>
    </ag-grid-table>
  </section>
</template>
      
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import VueSlider from 'vue-slider-component'

import useApiServices from '@/services/useApiServices.js';



import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,



  },

  beforeUnmount()
  {
    //this.$pusher.unsubscribe('optimizations');

    if (this.timeoutId)
    {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }


  },

  beforeRouteLeave(to, from, next)
  {

    //this.$pusher.unsubscribe('optimizations');

    if (this.timeoutId)
    {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }

    next() 
  },

  methods: {

    processNotFinished()
    {
      console.log("processNotFinished")

      let self = this

      let filteredNodes = self.$refs['grid-historico-optimizations'].filterNodes(node => ['RUNNING', 'STARTING', 'ERROR'].includes(node.data.status))

      let ids = filteredNodes.map(node => node.data.id);

      //console.log({filteredNodes, ids})

      useApiServices.getReadByIds(ids)
        .then(response =>
        {

          response.data.forEach(invocation =>
          {

            let invocation_id = invocation.id
            let invocation_updated_at = invocation.updated_at

            let rowNode = self.$refs['grid-historico-optimizations'].getRowNodeById(invocation_id)

            if (rowNode)
            {

              let row_node_updated_at = rowNode.data.updated_at

              let invocation_updated_at_date = Date.parse(invocation_updated_at)
              let row_node_updated_at_date = Date.parse(row_node_updated_at)

              if (invocation_updated_at_date > row_node_updated_at_date)
              {
                self.$refs['grid-historico-optimizations'].updateRow(invocation_id, invocation)
              }


            }



          })

          this.timeoutId = setTimeout(
            () =>
            {
              this.processNotFinished()
            },
            this.timeoutDelay
          );

        })
        .catch(() =>
        {

          this.timeoutId = setTimeout(
            () =>
            {
              this.processNotFinished()
            },
            this.timeoutDelay
          );

        })
    },


    accionVerDetalles(data)
    {
      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;

      this.$router.push({ name: 'optimization-details', query: { optimization_id: optimization_id } })

    },





    compareOptimizations()
    {

      this.$router.push({ name: 'compare-optimizations', query: { ids: this.selectedRows.map(run => run.id) } })
    },



    onGridReady(gridApi, gridColumnApi, params)
    {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })

      if (!this.timeoutId)
      {
        this.timeoutId = setTimeout(
          () =>
          {
            this.processNotFinished()
          },
          this.timeoutDelay
        );
      }

    },


    onSelectionChanged(selectedRows)
    {

      console.log(selectedRows)

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)
      this.disableRellenar = !(selectedRows.length == 1)

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {


    },



  },




  created()
  {



  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {

      debug: debug,

      timeoutId: null,
      timeoutDelay: 10000,

      showOverlay: false,
      overlayMessage: "Cargando...",

      getRowNodeId: (params) =>
      {
        return params.id;
      },
      enableCellChangeFlash: true,

      rowClassRules: {

        'escenario-base': (params) =>
        {
          if (params.data == undefined)
          {
            return false;
          }

          return params.data.default === 1;
        }

      },

      gridApi: null,

      itemsData: [],
      pinnedTopRowData: [],

      historicoOptimizationsConfig: useApiServices.historicoOptimizationsConfig,
      historicoOptimizationsData: useApiServices.historicoOptimizationsData,

      disableCompareRuns: true,
      disableRellenar: true,
      selectedRows: [],


      service_name: null,
      metrics: [],

      actions: [
        { name: "Ver Detalles", icon: "DownloadCloudIcon", action: this.accionVerDetalles },
      ],





    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
    
    
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
    
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
      