<template>
  <section id="">
 


    <b-tabs>
      <b-tab :title="$t('Histórico de Optimizaciones')">
        <optimizaciones-tab ref="optimizations-tab"></optimizaciones-tab>
      </b-tab>

      <b-tab :title="$t('Dashboard')">

        <bruna-element-loader
          :dataUrl="compareOptimizationsVersusRealChart"
          ref="compareOptimizationsVersusRealChartRef"
        >
        </bruna-element-loader>

      </b-tab>
    </b-tabs>


  </section>
</template>
    
    <script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import VueSlider from 'vue-slider-component'

import useApiServices from '@/services/useApiServices.js';

import OptimizacionesTab from './tabs/OptimizacionesTab.vue'

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,

    OptimizacionesTab,
    BrunaElementLoader



  },

  methods: {

   


  },

  beforeUnmount()
  {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next)
  {

    this.$pusher.unsubscribe('optimizations');

    next()
  },


  mounted()
  {

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data)
    {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id)
      {
        let invocation_id = data.invocation_id

        useApiServices.getInvocation(invocation_id)
          .then((response) =>
          {
            let invocation = response.data

            let optimizations_tab_ref = self.$refs['optimizations-tab']

            if(optimizations_tab_ref && optimizations_tab_ref.$refs['grid-historico-optimizations'])
            {
              optimizations_tab_ref.$refs['grid-historico-optimizations'].updateRow(invocation_id, invocation)
            }
            

          })
          .catch(function (error) {
            console.log(error)
          })



      }


    });




  },

  created()
  {



  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {

      debug: debug,


      compareOptimizationsVersusRealChart: useApiServices.historicoOptimizaciones.compareOptimizationsVersusRealChart

    };
  },
};
    </script>
    <style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
  
  
  <style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
  
  <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
    